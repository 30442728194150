<template>
  <div class="xie-yi">
    <van-checkbox @change="update" v-model="checked">
      我已阅读并同意 <router-link :to="{name:'XieYi',params:{id:10}}">《用户协议》</router-link>和 <router-link :to="{name:'XieYi',params:{id:11}}">《隐私政策》</router-link>
    </van-checkbox>

  </div>
</template>

<script>
export default {
  name: 'XieYi',
}
</script>
<script setup>
/**
 *  <xie-yi v-model:val="form.xie_yi"/>
 */
import {ref, defineEmit, onMounted} from 'vue'

const emit = defineEmit(['input','update'])
let checked = ref(false)
onMounted(function () {
  update()
})

function update() {
  emit('update:val', checked)
}
</script>

<style scoped>
.xie-yi {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}
</style>

<template>
  <div class="logo-div">
    <van-image class="logo" :src="login"/>
  </div>
</template>

<script>
export default {
  name: 'UserLoginTop',
}
</script>
<script setup>
let login = require("@/assets/icon/login.png")
</script>

<style scoped lang="less">
.logo-div {
  text-align: center;
  //margin-top: 50px;
  padding-top: 50px;

  .logo {
    width: 163px;
  }
}
</style>

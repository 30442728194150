import {Dialog} from 'vant'
import http from "@/api/http";

//登入同意协议
export async function TongYiXieYi() {
    let data = await http.get("/portal/index/getXieYiTiShi")

    return Dialog.confirm({
        allowHtml: true,
        cancelButtonText: "不同意",
        confirmButtonText: "同意",
        title: data.data.else_content,
        message: data.data.content,
    })
}

// export default {
//     TongYiXieYi
// }

<template>

  <div class="bg-fff">
    <div class="login-nei-rong">
      <div>
        <nav-header fixed title="账号密码登入"/>
      </div>
      <div class="login-body">
        <user-login-top/>
        <div class="body-margin input-css">
          <van-form @submit="onSubmit">
            <van-field
                v-model="form.mobile"
                placeholder="请输入手机号"
            />
            <van-field
                v-model="form.user_pass"
                type="password"
                placeholder="请输入密码"
            />
            <div class="margin-top-40">
              <van-button round block type="primary" native-type="submit">
                提交
              </van-button>
            </div>
          </van-form>
          <van-row class="ti-shi">
            <van-col :span="12">
              <a href="javascript:" @click="$router.push({name:'MobileLogin'})">手机验证码</a>
            </van-col>
            <van-col :span="12" class="text-right">
              <a href="javascript:" @click="$router.push({name:'EditPassword'})">忘记密码</a>
            </van-col>
          </van-row>
        </div>
      </div>
      <div class="login-xie-yi">
        <xie-yi v-model:val="form.xie_yi"/>
      </div>
    </div>
  </div>
</template>

<script>
//账号密码登入
import NavHeader from "../../components/top/NavHeader";

export default {
  name: 'NumberLogin',
  components: {NavHeader},
}
</script>
<script setup>
import UserLoginTop from "./public/UserLoginTop";
import XieYi from "./public/XieYi";
import {ref} from 'vue'
import {useRouter} from 'vue-router'
import http from "../../api/http";
import {setUserInfo} from '@/api/cookie'
import din_shi from "../../api/din_shi";
import {updateUserInfo, getReturnUrl} from "../../api/cookie";
import {TongYiXieYi} from "@/api/AlertMessage";

const router = useRouter()
let form = ref({})

function onSubmit() {
  if (!form.value.xie_yi) {
    TongYiXieYi().then(() => {
      form.value.xie_yi = true
      dengRu()
    })
  } else {
    dengRu()
  }

}

function dengRu() {
  http.post('/gzhphp/login/Numberlogin', form.value).then(da => {
    if (da.code === 1) {
      setUserInfo(da.data)
      router.push(getReturnUrl())
    }
  })
}

function wxLogin() {
  if (!form.value.xie_yi) {
    din_shi.error('请同意协议')
    return
  }
  location.href = '/gzhphp/login/wxLogin'
}
</script>

<style scoped lang="less">
@import "../../assets/login";

</style>
